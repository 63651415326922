import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IAccount, ICreateAccount, ICreateMyLicensesAccount, IUpdateAccount } from 'src/deprecated/models-old/account/account.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  headers = new HttpHeaders().set('Content-Type', 'application/json').set('Accept', 'application/json');
  httpOptions = { headers: this.headers };
  account$ = new BehaviorSubject(undefined);
  accountInfo = this.account$.asObservable();
  public partnerAccounts$: BehaviorSubject<IAccount[]> = new BehaviorSubject<IAccount[]>(null);
  public customerAccounts$: BehaviorSubject<IAccount[]> = new BehaviorSubject<IAccount[]>(null);
  private apiUri = environment.apiUrl + 'Permission/';

  constructor(private http: HttpClient) {
    // this.getPartnerAccounts();
    // this.getCustomerAccounts();
  }

  updateAccountInfo(account) {
    this.account$.next(account);
  }

  getPartnerAccountsObs(): Observable<IAccount[]> {
    return this.http.get<IAccount[]>(this.apiUri + 'Accounts/Partner');
  }

  getPartnerAccountByAuthIds(ids: string[]): Observable<IAccount> {
    return this.http.post<IAccount>(this.apiUri + 'Accounts/Partner/by-id/multiple', ids);
  }

  getCustomerAccountByAuthIds(ids: string[]): Observable<IAccount> {
    return this.http.post<IAccount>(this.apiUri + 'Accounts/Customer/by-id/multiple', ids);
  }

  getCustomerAccountsObs(): Observable<IAccount[]> {
    return this.http.get<IAccount[]>(this.apiUri + 'Accounts/Customer');
  }

  getPartnerAccounts() {
    return this.http.get<IAccount[]>(this.apiUri + 'Accounts/Partner').subscribe((data: IAccount[]) => {
      this.partnerAccounts$.next(data);
    });
  }

  getCustomerAccounts() {
    return this.http.get<IAccount[]>(this.apiUri + 'Accounts/Customer').subscribe((data: IAccount[]) => {
      this.customerAccounts$.next(data);
    });
  }

  resetMFA(id: string): Observable<Object> {
    return this.http.delete(this.apiUri + 'Account/' + id + '/MFA');
  }

  createAccount(item: ICreateAccount): Observable<IAccount> {
    return this.http.post<IAccount>(this.apiUri + 'Account', item);
  }

  createMyLicensesAccount(item: ICreateMyLicensesAccount): Observable<IAccount> {
    return this.http.post<IAccount>(this.apiUri + 'Account', item);
  }

  updateAccount(id: IAccount['id'], item: IUpdateAccount): Observable<IUpdateAccount> {
    return this.http.put<IUpdateAccount>(this.apiUri + 'Account/' + id, item);
  }

  deleteAccount(id: IAccount['id']): Observable<IAccount> {
    return this.http.delete<IAccount>(this.apiUri + 'Account/' + id, this.httpOptions);
  }

  public resetPassword(id: IAccount['id']): Observable<boolean> {
    return this.http.patch<boolean>(this.apiUri + 'Account/' + id + '/ResetPassword', {});
  }
}
